import React, { FC } from "react";

import Carousel from "components/Carousel";
import Feature from "components/Feature";
import styles from "./styles.module.scss";
import { desktopFeatures } from "./constants";

const Features: FC = () => {
  return (
    <Carousel className={styles.carousel}>
      {desktopFeatures.map((feature) => (
        <Feature {...feature} />
      ))}
    </Carousel>
  );
};

export default Features;
