import React from "react";
import Head from "next/head";
import { ExtendedNextPage } from "types/pages";

import styles from "./styles.module.scss";
import Topbar from "../components/Topbar";
import {
  MainHeadline,
  StartHumming,
  Features,
  SignupBanner,
  TheHHValue,
  WhereWeAreLocated,
  Growth,
  WhatWeOffer,
  WeAreTheExperts,
  HowItWorks
} from "./components";
import Footer from "../components/Footer";

const HomePage: ExtendedNextPage = () => {
  return (
    <div className={styles.container}>
      <Head>
        <title>Humming Homes</title>
        <meta name="home-page" content="Humming home's home page" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <MainHeadline />
      <Features />
      <WhatWeOffer />
      <WeAreTheExperts />
      <HowItWorks />
      <SignupBanner />
      <TheHHValue />
      <Growth />
      <WhereWeAreLocated />
      <StartHumming fillColor="white" />
    </div>
  );
};

HomePage.getLayout = (page) => {
  return (
    <div>
      <Topbar withHeader />
      {page}
      <Footer />
    </div>
  );
};

export default HomePage;
