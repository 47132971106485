import Button from "components/Button";
import Content from "components/Content";
import Label from "components/Label";
import Media from "components/Media";
import { useRouter } from "next/router";
import React, { FC } from "react";

import styles from "./styles.module.scss";

const SignupBanner: FC = () => {
  const router = useRouter();

  return (
    <Content
      disableMotion
      contentClassName={styles.container}
      containerClassName={styles.wrapper}
    >
      <Media at={["desktop"]}>
        <Label className={styles.label} variant="h4" color="primary2">
          Take home maintenance off your plate
        </Label>
      </Media>
      <Media at={["mobile", "tablet"]}>
        <Label className={styles.label} variant="h2-centered" color="primary2">
          Take home maintenance off your plate
        </Label>
      </Media>
      <Button
        variant="secondary"
        onClick={() => {
          router.push("/start");
        }}
      >
        Sign up
      </Button>
    </Content>
  );
};

export default SignupBanner;
