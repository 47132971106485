import Label from "components/Label";
import useAnimatedValue from "hooks/use-animated-value";
import React, { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";

import styles from "./styles.module.scss";

type Props = {
  value: number;
  withPostfix?: boolean;
  title: string;
  animated?: boolean;
};

const AnimatedGrowth: FC<Props> = ({ value, withPostfix, title, animated }) => {
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  const { animate, animatedValue, animationFinished } = useAnimatedValue({
    transition: "easeOut"
  });

  useEffect(() => {
    if (triggerAnimation) animate(0, value, 2500);
  }, [triggerAnimation]);

  return (
    <motion.div
      onViewportEnter={() => setTriggerAnimation(true)}
      className={styles.metric}
    >
      <div className={styles.value}>
        <Label variant="h2" color="white">
          {animated ? Math.round(animatedValue) : value}
        </Label>
        {withPostfix && animationFinished && (
          <Label variant="h2" color="white" className={styles.postfix}>
            +
          </Label>
        )}
      </div>
      <Label variant="centered-body" color="white">
        {title}
      </Label>
    </motion.div>
  );
};

AnimatedGrowth.defaultProps = {
  withPostfix: false,
  animated: false
};

export default AnimatedGrowth;
