import Button from "components/Button";
import Content from "components/Content";
import Label from "components/Label";
import Link from "components/Link";
import Media from "components/Media";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { FC } from "react";
import { FiArrowRight as ArrowRight } from "react-icons/fi";

import Mandala from "../../../../../public/homepage/what_we_offer/what_we_offer.png";
import { bullets } from "./constants";

// import { items } from "./constants";
import styles from "./styles.module.scss";

const WhatWeOffer: FC = () => {
  const router = useRouter();

  return (
    <Content
      contentClassName={styles.container}
      containerClassName={styles.wrapper}
    >
      <Label className={styles.preTitle} variant="h-centered" color="primary">
        A digital subscription for all your home services needs
      </Label>
      <Label className={styles.title} variant="h-centered" color="primary">
        Your membership
      </Label>
      <Link
        className={styles.link}
        variant="capitalized"
        color="accent"
        to="/services"
        label="How we do it"
        Icon={<ArrowRight />}
      />
      <div className={styles.content}>
        <div className={styles.leftPanel}>
          <Label className={styles.text} variant="s" color="secondary">
            Being a Humming Homes member comes with (a ton) of perks. You have
            access to a team of experts who help you tackle home projects on
            your to-do list. You know the ones we’re talking about:
          </Label>
          {bullets.map((bullet) => (
            <Label
              className={`${styles.text} ${styles.bullet}`}
              useMarkdown
              color="secondary"
              variant="s"
            >
              {`- ${bullet}`}
            </Label>
          ))}
          <Button
            onClick={() => router.push("/start")}
            className={styles.signUp}
            variant="accent"
          >
            Sign up
          </Button>
        </div>
        <div className={styles.rightPanel}>
          <Media at={["desktop"]}>
            <Image layout="fixed" width={400} height={355} src={Mandala} />
          </Media>
          <Media at={["tablet"]}>
            <Image layout="fixed" width={500} height={444} src={Mandala} />
          </Media>
          <Media at={["mobile"]}>
            <Image layout="fixed" width={300} height={266} src={Mandala} />
          </Media>
        </div>
      </div>
    </Content>
  );
};

export default WhatWeOffer;
