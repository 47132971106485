import React, { FC } from "react";
import Label from "components/Label";
import Button from "components/Button";
import Content from "components/Content";
import { useRouter } from "next/router";
import Media from "components/Media";

import Image from "next/image";
import HeadlineMandala from "../../../../../public/homepage/main_headline/headline-mandala.png";

import styles from "./styles.module.scss";

const MainHeadline: FC = () => {
  const router = useRouter();

  return (
    <Content contentClassName={styles.container}>
      <div className={styles.heading}>
        <div className={styles.header}>
          <Label className={styles.title} variant="h" color="primary">
            Your life is busy.
          </Label>
          <Label className={styles.subtitle} variant="h" color="primary">
            We take home management off your plate.
          </Label>
          <Label className={styles.info} variant="s" color="primary">
            Humming Homes is your dedicated home management solution. We take
            care of everything — from booking vendors to house watching while
            you’re away. All through our app, so it’s as easy as 1, 2 —
            landscaping done!
          </Label>
        </div>
        <Button
          className={styles.cta}
          variant="accent"
          onClick={() => router.push("/services")}
        >
          Learn more
        </Button>
      </div>
      <div className={styles.getEstimate}>
        <Media at={["desktop"]} className={styles.estimateBackground}>
          <Image
            layout="fixed"
            height={800}
            width={800}
            src={HeadlineMandala}
          />
        </Media>
        <Media at={["tablet"]} className={styles.estimateBackground}>
          <Image
            layout="fixed"
            height={540}
            width={540}
            src={HeadlineMandala}
          />
        </Media>
        <Media at={["mobile"]} className={styles.estimateBackground}>
          <Image
            layout="responsive"
            height="150vw"
            width="150vw"
            src={HeadlineMandala}
          />
        </Media>
      </div>
    </Content>
  );
};

export default MainHeadline;
