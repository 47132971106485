import { useEffect, useState } from "react";

import useMutableState from "../use-mutable-state";

import { TRANSITION_FUNCTIONS } from "./constants";

type Config = {
  transition: "linear" | "easeInOut" | "easeIn" | "easeOut";
};

const useAnimatedValue = (config: Config = { transition: "easeIn" }) => {
  const [animationFinished, setAnimationFinished] = useState(false);
  const [animatedValue, setAnimatedValue] = useMutableState(0);
  const [currentInterval, setCurrentInterval] = useState<NodeJS.Timer | null>(
    null
  );
  const [totalSteps, setTotalSteps] = useState(0);
  const [index, setIndex] = useMutableState(0);

  const { transition } = config;

  useEffect(() => {
    if (index.current === totalSteps && currentInterval) {
      clearInterval(currentInterval);
      setAnimationFinished(true);
    }
  }, [animatedValue.current]);

  const animate = (from: number, to: number, duration: number) => {
    setAnimationFinished(false);

    if (currentInterval) clearInterval(currentInterval);
    setAnimatedValue(from);
    setIndex(0);

    const steps = Math.ceil(duration / 5);
    setTotalSteps(steps);

    const interval = setInterval(() => {
      setIndex(index.current + 1);
      if (to < from)
        setAnimatedValue(
          (1 - TRANSITION_FUNCTIONS[transition](index.current / steps)) *
            (from - to) +
            to
        );
      else
        setAnimatedValue(
          TRANSITION_FUNCTIONS[transition](index.current / steps) *
            (to - from) +
            from
        );

      if (index.current === steps - 1) setAnimatedValue(to);
    }, 5);

    setCurrentInterval(interval);
  };

  return { animate, animatedValue: animatedValue.current, animationFinished };
};

export default useAnimatedValue;
