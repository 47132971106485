import Button from "components/Button";
import Content from "components/Content";
import Label from "components/Label";
import Media from "components/Media";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { FC } from "react";
import AppleStore from "../../../../../public/apple-store.svg";

import { steps } from "./constants";

import styles from "./styles.module.scss";

const WeAreTheExperts: FC = () => {
  const router = useRouter();

  return (
    <Content contentClassName={styles.container}>
      <Label color="primary" className={styles.title} variant="centered-h">
        We’re the experts on home services
      </Label>
      <Label color="primary" className={styles.subtitle} variant="centered-s">
        Whatever you need for your home, we can handle it. We have a vendor
        network that we vet, select and deploy as necessary.
      </Label>
      <Button
        variant="text"
        className={styles.iconButton}
        onClick={() => router.push("/start")}
        Icon={<AppleStore className={styles.icon} />}
      />
      <div className={styles.steps}>
        {steps.map(({ title, content, src, mobileSrc }) => (
          <div className={styles.step}>
            <Media className={styles.imageWrapper} at={["desktop", "tablet"]}>
              <Image height={250} width={250} layout="fixed" src={src} />
            </Media>
            <Media className={styles.imageWrapper} at={["mobile"]}>
              <Image layout="fill" src={mobileSrc.src} />
            </Media>
            <div className={styles.stepContent}>
              <Label color="primary" variant="h" className={styles.stepTitle}>
                {title}
              </Label>
              <Label
                color="primary"
                variant="s"
                className={styles.stepInformation}
              >
                {content}
              </Label>
            </div>
          </div>
        ))}
      </div>
      <Button
        className={styles.learnMore}
        variant="accent"
        onClick={() => router.push("/services")}
      >
        Learn more
      </Button>
    </Content>
  );
};

export default WeAreTheExperts;
