import { useState, useRef, MutableRefObject } from "react";

const useMutableState = <T>(
  initialValue: T
): [MutableRefObject<T>, (newValue: T) => void] => {
  const [value, setValue] = useState<T>(initialValue);
  const valueRef = useRef(value);
  valueRef.current = value;

  return [valueRef, setValue];
};

export default useMutableState;
