import Step1 from "../../../../../public/homepage/how_it_works/step_1.png";
import Step2 from "../../../../../public/homepage/how_it_works/step_2.png";
import Step3 from "../../../../../public/homepage/how_it_works/step_3.png";
import Step4 from "../../../../../public/homepage/how_it_works/step_4.png";
import Step5 from "../../../../../public/homepage/how_it_works/step_5.png";
import Step1Mobile from "../../../../../public/homepage/how_it_works/step_1_mobile.png";
import Step2Mobile from "../../../../../public/homepage/how_it_works/step_2_mobile.png";
import Step3Mobile from "../../../../../public/homepage/how_it_works/step_3_mobile.png";
import Step4Mobile from "../../../../../public/homepage/how_it_works/step_4_mobile.png";
import Step5Mobile from "../../../../../public/homepage/how_it_works/step_5_mobile.png";

export const steps = [
  {
    src: Step1,
    mobileSrc: Step1Mobile,
    title: "Get your estimate",
    content:
      "Book time, and chat with us to get a personalized estimate for your home"
  },
  {
    src: Step2,
    mobileSrc: Step2Mobile,
    title: "Meet your team",
    content:
      "Meet a dedicated home coordinator and experts who will get you set up with HH"
  },
  {
    src: Step3,
    mobileSrc: Step3Mobile,
    title: "Onboard - virtually & physically",
    content:
      "Download the app directly and we’ll onboard your home in-person with a local expert, catalog your room & appliance data and understand your needs"
  },
  {
    src: Step4,
    mobileSrc: Step4Mobile,
    title: "Receive your year-round maintenance",
    content:
      "We’ll schedule and oversee everything from your weekly home cleaning to your monthly lawn maintenance, to your bi-annual gutter cleaning, making home maintenance easy. "
  },
  {
    src: Step5,
    mobileSrc: Step5Mobile,
    title: "Submit projects & start humming",
    content:
      "You can use the app to submit your projects, get expert advice on home improvement, manage your vendors and communication in one place! Relax, as your home is taken care of. "
  }
];
