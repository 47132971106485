import Content from "components/Content";
import Label from "components/Label";
import React, { FC } from "react";
import AnimatedGrowth from "./components/AnimatedGrowth";
import { metrics } from "./constants";
import styles from "./styles.module.scss";

const Growth: FC = () => {
  return (
    <Content
      containerClassName={styles.wrapper}
      contentClassName={styles.container}
    >
      <Label className={styles.title} variant="h2-centered" color="white">
        Humming Homes is growing fast
      </Label>
      <div className={styles.metrics}>
        {metrics.map(({ value, title, withPostfix, animated }) => (
          <AnimatedGrowth
            animated={animated}
            withPostfix={withPostfix}
            key={title}
            title={title}
            value={value}
          />
        ))}
      </div>
    </Content>
  );
};

export default Growth;
