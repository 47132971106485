import Content from "components/Content";
import Label from "components/Label";
import ScrollView from "components/ScrollView";
import StarRating from "components/StarRating";
import Image from "next/image";
import React, { FC } from "react";
import { locations } from "./constants";
import styles from "./styles.module.scss";

const WhereWeAreLocated: FC = () => {
  return (
    <Content id="locations" contentClassName={styles.container}>
      <Label variant="h2-centered" color="primary">
        Where we are located
      </Label>
      <Label className={styles.subtitle} variant="s2-centered" color="primary">
        We’re currently humming along in the Hamptons, NY, Miami, FL, Fairfield
        County, CT, and Westchester County, NY.
      </Label>
      <ScrollView
        className={styles.locations}
        itemWidth={`${100 / locations.length}%`}
      >
        {locations.map(({ location, review, locName }) => (
          <div className={styles.location}>
            <Image
              layout="fixed"
              width={200}
              height={250}
              src={location}
              alt=""
            />
            <Label
              className={styles.locName}
              variant="body-centered"
              color="primary"
            >
              {locName}
            </Label>
            <StarRating className={styles.rating} stars={review.starts} />
            <div className={styles.review}>
              <Label
                className={styles.reviewContent}
                color="primary"
                variant="small-centered"
              >
                {review.content}
              </Label>
              <div>
                <Label
                  className={styles.reviewer}
                  color="supplemental"
                  variant="xsmall-capitalized-centered"
                >
                  {review.reviewer}
                </Label>
                <Label
                  className={styles.role}
                  color="supplemental"
                  breakWord
                  variant="xsmall-capitalized-centered"
                >
                  {review.role}
                </Label>
              </div>
            </div>
          </div>
        ))}
      </ScrollView>
    </Content>
  );
};

export default WhereWeAreLocated;
