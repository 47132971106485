import RealDeal from "../../../../../public/homepage/features/real-deal.svg";
import InMan from "../../../../../public/homepage/features/in-man.svg";
import Yahoo from "../../../../../public/homepage/features/yahoo.svg";
import LocalMoms from "../../../../../public/homepage/features/local-moms.svg";

const baseDesktopFeatures = [
  {
    key: "yahoo-desktop",
    Logo: Yahoo,
    review: `"Humming Homes is concentrating homeowner demand and push localized marketplaces that provide better availability, better pricing and a better consumer experience - that's what homeowners need."`
  },
  {
    key: "rd-desktop",
    Logo: RealDeal,
    review:
      "“Humming Homes will set them up and take care of everything from helping you move in, getting your HVAC system fixed or installing a new roof.”"
  },
  {
    key: "im-desktop",
    Logo: InMan,
    review: `“Douglas Elliman parent company invests in another proptech startup."`
  },
  {
    key: "local-moms",
    Logo: LocalMoms,
    review: `“Game changer for families everywhere ! When Humming Homes tells us a vendor is coming, we know they are actually coming.”`
  }
];

export const desktopFeatures = [
  ...baseDesktopFeatures,
  ...baseDesktopFeatures,
  ...baseDesktopFeatures
].map((feature, index) => ({ ...feature, key: `${feature.key}${index}` }));
