import React, { ElementType, FC } from "react";

import Label from "components/Label";
import styles from "./styles.module.scss";

type Props = {
  review: string;
  Logo: ElementType;
};

const Feature: FC<Props> = ({ review, Logo }) => {
  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />
      <Label variant="body-centered" color="primary">
        {review}
      </Label>
    </div>
  );
};

export default Feature;
