import Repairs from "../../../../../public/homepage/we_are_the_experts/repairs.png";
import DreamHome from "../../../../../public/homepage/we_are_the_experts/dream-home.png";
import Maintenance from "../../../../../public/homepage/we_are_the_experts/maintenance.png";
import RepairsMobile from "../../../../../public/homepage/we_are_the_experts/repairs-mobile.png";
import DreamHomeMobile from "../../../../../public/homepage/we_are_the_experts/dream-home-mobile.png";
import MaintenanceMobile from "../../../../../public/homepage/we_are_the_experts/maintenance-mobile.png";

export const steps = [
  {
    title: "Tackling your move-in repairs",
    content:
      "When you buy a house, the point of sale is a cluster of documents, inspection checklists, and future to-do’s. We help you tackle those to-dos’ before or after you’ve moved in.",
    src: Repairs,
    mobileSrc: RepairsMobile
  },
  {
    title: "Seasonal & proactive maintenance",
    content:
      "We know what to do when something has broken, or something is about to break. With handymen as part of your subscription and a complete repository of all of your home’s appliances, we’ll make sure your home runs smoothly.",
    src: Maintenance,
    mobileSrc: MaintenanceMobile
  },
  {
    title: "Making your house your dream home",
    content:
      "Whether you want to redo your kitchen or replace your roof, we have the right tile, flooring and roofing contractors.",
    src: DreamHome,
    mobileSrc: DreamHomeMobile
  }
];
