export const metrics = [
  {
    value: 400,
    title: "Homes we manage",
    withPostfix: true,
    animated: true
  },
  {
    value: 5,
    title: "Markets",
    animated: false
  },
  {
    value: 3000,
    title: "Home service projects",
    withPostfix: true,
    animated: true
  },
  {
    value: 2000,
    title: "Vendors in-network",
    withPostfix: true,
    animated: true
  }
];
