const LINEAR = "linear";
const EASE_IN = "easeIn";
const EASE_OUT = "easeOut";
const EASE_IN_OUT = "easeInOut";
const DEFAULT = "undefined";

const TRANSITION_CONSTANT = 3.5;

export const TRANSITION_FUNCTIONS: { [key: string]: (k: number) => number } = {
  [LINEAR]: (k: number): number => k,
  [EASE_IN]: (k: number): number => k ** TRANSITION_CONSTANT,
  [EASE_OUT]: (k: number): number => 1 - (1 - k) ** TRANSITION_CONSTANT,
  [EASE_IN_OUT]: (k: number): number =>
    0.5 * (Math.sin((k - 0.5) * Math.PI) + 1),
  [DEFAULT]: (k: number): number => k
};
