/* eslint-disable @typescript-eslint/naming-convention */
import Button from "components/Button";
import Content from "components/Content";
import Form from "components/Form";
import Label from "components/Label";
import React, { FC } from "react";
import Media from "components/Media";
import { useRouter } from "next/router";
import useAsync from "hooks/use-async";
import toast from "react-hot-toast";
import { formatErrors, required } from "utils/form";
import DesktopBackground from "../../../../public/desktop-background.svg";
import MobileBackground from "../../../../public/mobile-background.svg";

import styles from "./styles.module.scss";

type Props = {
  fillColor?: string;
};

type FormValues = {
  full_name: string;
  email: string;
  phone: string;
};

const StartHumming: FC<Props> = ({ fillColor = "white" }) => {
  const router = useRouter();

  const parseValues = (values: FormValues) => {
    const newValues: { [key: string]: string } = { ...values };

    delete newValues.home_owner_type;
    delete newValues.full_name;

    const [firstname, ...lastname] = values.full_name.split(" ");

    newValues.firstname = firstname;
    newValues.lastname = lastname.join(" ");

    return newValues;
  };

  const [submit, submitting] = useAsync(async (values: FormValues) => {
    try {
      const parsedValues = parseValues(values);

      toast.success("Thank you! We got your info!", { duration: 2000 });

      router.push({ pathname: "/start", query: parsedValues });
    } catch (e) {
      toast.error("Something went wrong", { duration: 2000 });
    }
  });

  return (
    <Content
      disableMotion
      containerClassName={styles.wrapper}
      contentClassName={styles.container}
    >
      <>
        <Media at={["desktop", "tablet"]}>
          <DesktopBackground
            className={`${styles.background} ${styles[`${fillColor}Fill`]}`}
          />
        </Media>
        <Media at={["mobile"]}>
          <MobileBackground className={styles.background} />
          <div
            className={`${styles.mobileFill} ${styles[`${fillColor}Fill`]}`}
          />
        </Media>
        <div className={styles.content}>
          <Label variant="h2-centered" color="primary2">
            Sign up for your free estimate
          </Label>
          <Label
            className={styles.subtitle}
            variant="s2-centered"
            color="primary2"
          >
            ... and start living in your home like a renter.
          </Label>
          <Media className={styles.formWrapper} at={["desktop", "tablet"]}>
            <Form
              onError={(errors) =>
                toast.error(formatErrors(errors), { position: "top-right" })
              }
              initialValues={{ home_owner_type: "owner" }}
              className={styles.form}
              onSubmit={submit}
              fields={[
                [
                  {
                    id: "full_name",
                    type: "text",
                    placeholder: "Full name",
                    validate: [required("Full name is required")]
                  },
                  {
                    id: "email",
                    placeholder: "Email address",
                    type: "text",
                    validate: [required("Email is required")]
                  },
                  {
                    id: "phone",
                    placeholder: "Phone number",
                    type: "text",
                    validate: [required("Phone is required")]
                  }
                ]
              ]}
            >
              {() => (
                <Button
                  type="submit"
                  className={styles.submit}
                  variant="accent"
                >
                  Get your free estimate
                </Button>
              )}
            </Form>
          </Media>
          <Media at={["mobile"]}>
            <Form
              onError={(errors) =>
                toast.error(formatErrors(errors), { position: "top-right" })
              }
              initialValues={{ home_owner_type: "owner" }}
              className={styles.form}
              onSubmit={submit}
              fields={[
                {
                  id: "full_name",
                  type: "text",
                  placeholder: "Full name",
                  validate: [required("Full name is required")]
                },
                {
                  id: "email",
                  placeholder: "Email address",
                  type: "text",
                  validate: [required("Email is required")]
                },
                {
                  id: "phone",
                  placeholder: "Phone number",
                  type: "text",
                  validate: [required("Phone is required")]
                }
              ]}
            >
              {() => (
                <Button
                  type="submit"
                  className={styles.submit}
                  variant="accent"
                  loading={submitting}
                >
                  Get your free estimate
                </Button>
              )}
            </Form>
          </Media>
        </div>
      </>
    </Content>
  );
};

StartHumming.defaultProps = {
  fillColor: "primary"
};

export default StartHumming;
