import Content from "components/Content";
import Label from "components/Label";
import Media from "components/Media";
import Image from "next/image";
import React, { FC } from "react";
import { steps } from "./constants";

import styles from "./styles.module.scss";

const HowItWorks: FC = () => (
  <Content contentClassName={styles.container}>
    <Label variant="h-centered" className={styles.title}>
      How does Humming Homes work?
    </Label>
    <Label variant="s-centered" className={styles.subtitle}>
      We make a plan and put it in place — from seasonal maintenance to bigger
      projects.
    </Label>
    <Media at={["desktop", "tablet"]}>
      <div className={styles.steps}>
        {steps.map(({ src, title, content }) => (
          <div key={title} className={styles.step}>
            <Image width={323} height={417} src={src} layout="responsive" />
            <Label color="primary" variant="h" className={styles.stepTitle}>
              {title}
            </Label>
            <Label color="primary" variant="s" className={styles.stepContent}>
              {content}
            </Label>
          </div>
        ))}
      </div>
    </Media>
    <Media at={["mobile"]}>
      <div className={styles.steps}>
        {steps.map(({ mobileSrc, title, content }) => (
          <div style={{ maxWidth: 340 }} className={styles.step}>
            <Image src={mobileSrc} layout="fixed" width={137} height={137} />
            <div className={styles.contentWrapper}>
              <Label color="primary" variant="h" className={styles.stepTitle}>
                {title}
              </Label>
              <Label color="primary" variant="s" className={styles.stepContent}>
                {content}
              </Label>
            </div>
          </div>
        ))}
      </div>
    </Media>
  </Content>
);

export default HowItWorks;
