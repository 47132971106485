import Label from "components/Label";
import React, { FC } from "react";
import Image from "next/image";
import { BsFillCheckCircleFill as Check } from "react-icons/bs";

import Content from "components/Content";
import Media from "components/Media";
import MandalaDesktop from "../../../../../public/homepage/membership/mandala_desktop.png";
import MandalaTablet from "../../../../../public/homepage/membership/mandala_tablet.png";
import MandalaMobile from "../../../../../public/homepage/membership/mandala_mobile.png";
import styles from "./styles.module.scss";
import { values } from "./constants";

const TheHHValue: FC = () => {
  return (
    <Content
      contentClassName={styles.container}
      containerClassName={styles.wrapper}
    >
      <div className={styles.leftPanel}>
        <Media at={["desktop"]} className={styles.imageWrapper}>
          <Image
            layout="fixed"
            alt=""
            width={570}
            height={760}
            className={styles.image}
            src={MandalaDesktop}
          />
        </Media>
        <Media at={["tablet"]} className={styles.imageWrapper}>
          <Image
            layout="fixed"
            alt=""
            width={295}
            height={806}
            className={styles.image}
            src={MandalaTablet}
          />
        </Media>
        <Media at={["mobile"]} className={styles.imageWrapper}>
          <Image
            layout="fixed"
            alt=""
            width={350}
            height={384}
            className={styles.image}
            src={MandalaMobile}
          />
        </Media>
      </div>
      <div className={styles.rightPanel}>
        <Label className={styles.title} variant="h3" color="secondary">
          Your Humming Homes Membership gives you:
        </Label>
        <div className={styles.values}>
          {values.map((value) => (
            <div className={styles.value}>
              <Check className={styles.check} />
              <Label variant="s2" color="secondary">
                {value}
              </Label>
            </div>
          ))}
        </div>
      </div>
    </Content>
  );
};

export default TheHHValue;
