import Miami from "../../../../../public/miami.png";
import Fairfield from "../../../../../public/fairfield.png";
import Hamptons from "../../../../../public/hamptons.png";
import Westchester from "../../../../../public/westchester.png";

export const locations = [
  {
    location: Miami,
    locName: "Miami, FL",
    review: {
      starts: 5,
      reviewer: "GRACIE N",
      role: "HOMEOWNER",
      content:
        "“We love how organized the app is, for everything from communication to invoices. It’s really easy to track everything in one place, and the Humming Homes team is very responsive.”"
    }
  },
  {
    location: Westchester,
    locName: "Westchester County, NY",
    review: {
      starts: 5,
      reviewer: "JAY R",
      role: "HOMEOWNER",
      content:
        "“Humming Homes is an awesome service for managing your home in Westchester County. The app is really easy to use, their vendors are top-notch, and they give really helpful price/quality comparisons on local service providers.”"
    }
  },
  {
    location: Hamptons,
    locName: "Hamptons, NY",
    review: {
      starts: 5,
      reviewer: "MARK M",
      role: "HOMEOWNER",
      content:
        "“As first time homeowners in the Hamptons coming from out-of-state, we were concerned about finding the right service providers and being able to manage needs while away. The Humming Homes advisors have been professional, responsive and an expert on a range of services.”"
    }
  },
  {
    location: Fairfield,
    locName: "Fairfield County, CT",
    review: {
      starts: 5,
      reviewer: "LAYLA L",
      role: "HOMEOWNER",
      content:
        "“Humming Homes has helped me, tremendously. They came in, assessed all of my homes’ needs and then began to assign vetted vendors and contractors to my home. And when Humming Homes tells me a vendor is coming, I know they are actually coming.”"
    }
  }
];
